<template>
  <v-form ref="formBoxOpening" v-on:submit.prevent="saveForm">
    <div class="row mt-4 pt-3">
      <FormBoxCloseCoinsBills
        :boxProp="boxProp"
        :allRead="isClosing"
        @boxUpdated="onBoxUpdated"
      ></FormBoxCloseCoinsBills>

      <div class="col-12 d-flex">
        <v-btn
          v-if="!isClosing"
          type="submit"
          :loading="loading"
          :disabled="loading"
          tile
          dark
          color="primary"
          depressed
          class="mx-auto"
        >
          Cerrar caja
        </v-btn>
      </div>
    </div>
  </v-form>
</template>

<script>
import FormBoxCloseCoinsBills from "@/components/forms/FormBoxCloseCoinsBills";
import { mapActions } from "vuex";
import Swal from "sweetalert2";
import store from "../../store";
import axios from "@/api/entradaslima";

export default {
  components: {
    FormBoxCloseCoinsBills
  },
  props: {
    boxOpen: Object,
    boxProp: Object
  },
  data() {
    return {
      rounded: false,
      color: "#738297",
      bgColor: "#1b2431",
      required: [v => !!v || "Requerido"],
      upToZero: [v => (!!v && v > 0) || "Incorrecto"],
      disabled: false,
      details: false,
      filled: true,
      outlined: false,
      dense: true,
      autocomplete: "off",
      readonly: true,
      dark: true,
      //
      errorMessage: "",
      loading: false,
      //
      isOpening: (this.boxOpen?.IDESTADOBOLETERIA || 0) > 0,
      isClosing: (this.boxProp?.IDESTADOBOLETERIA || 0) > 0,
      baseIndex: 5465542,
      boxOpening: this.boxProp ? { ...this.boxProp } : {},

      idParque: store.getters.getSessionParqueId,
      usuario: store.getters.getSessionUser.CORREO,
      idAsignacion: store.getters.getSessionIdAsignacion || 0
    };
  },
  async created() {
    if (!this.isOpening) {
      this.generateSnackBarMessage({
        message: "La Caja no está aperturada",
        type: "error"
      });
      this.isClosing = true;
    } else {
      if (this.isClosing) {
        this.generateSnackBarMessage({
          message: "La Caja ya se encuentra cerrada"
        });
      }
    }
  },
  methods: {
    ...mapActions({
      generateConfirmDialog: "generateConfirmDialog",
      generateSnackBarMessage: "generateSnackBarMessage"
    }),
    validateForm() {
      return this.$refs.formBoxOpening.validate();
    },
    saveForm() {
      if (!this.validateForm()) return;
      this.saveBoxOpening(this.boxOpening);
    },
    async saveBoxOpening() {
      const response = await this.generateConfirmDialog({
        message: `¿Esta seguro de cerrar la caja?`,
        confirmMessage: "Si, quiero continuar"
      });
      if (response) {
        try {
          this.loading = true;
          const { data } = await axios.post(
            `/parques/${this.idParque}/asignaciones/${this.idAsignacion}/estados`,
            this.boxOpening
          );
          this.loading = false;
          this.generateSnackBarMessage({
            message: data.message
          });
          this.$emit("boxOpeningSaved");
        } catch (error) {
          this.loading = false;
          return Swal.fire({
            icon: "error",
            text: error.response.data.message || "Ocurrió un error interno"
          });
        }
      }
    },
    onBoxUpdated(ev) {
      this.boxOpening = {
        ...this.boxOpening,
        tipoEstado: "CF",
        cantidadM010: ev.coins[0]?.amount || 0,
        cantidadM020: ev.coins[1]?.amount || 0,
        cantidadM050: ev.coins[2]?.amount || 0,
        cantidadM1: ev.coins[3]?.amount || 0,
        cantidadM2: ev.coins[4]?.amount || 0,
        cantidadM5: ev.coins[5]?.amount || 0,
        cantidadB10: ev.bills[0]?.amount || 0,
        cantidadB20: ev.bills[1]?.amount || 0,
        cantidadB50: ev.bills[2]?.amount || 0,
        cantidadB100: ev.bills[3]?.amount || 0,
        cantidadB200: ev.bills[4]?.amount || 0,
        importeTarjeta: ev.other[0]?.subtotal || 0,
        importeQR: ev.other[1]?.subtotal || 0,
        usuario: this.usuario
      };
    }
  }
};
</script>

<style lang="scss" scoped></style>
