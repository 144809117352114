<template>
  <span :class="`text-amount text-light ${amount < 0 ? 'text-danger' : ''}`">{{
    formatAmountBill
  }}</span>
</template>

<script>
import { formatPE } from "@/common/price";
export default {
  props: {
    amount: {
      type: Number,
      default: 0
    }
  },
  computed: {
    formatAmountBill() {
      let number = this.amount ? parseFloat(this.amount) : 0;
      return formatPE(number);
    }
  }
};
</script>

<style lang="scss" scoped>
.text-amount {
  font-weight: 700;
  font-size: 13.5px;
}
.text-amount.text-danger {
  color: #dc3545 !important;
}
</style>
