<template>
  <div class="col-12 pb-2 pt-0">
    <div class="row">
      <div class="col-6">
        <table class="table table-bordered table-bill w-100">
          <thead>
            <tr class="text-light bg-dark">
              <th colspan="3" class="text-center title-table-bill">
                BILLETES
              </th>
            </tr>
            <tr class="text-light bg-dark">
              <th></th>
              <th>Cantidad</th>
              <th class="text-right" style="min-width: 120px">Monto</th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(c, idx) in box.bills">
              <tr v-if="c.isVisible" :key="idx + c.name" class="">
                <td class="vertical-middle">
                  <img
                    height="40"
                    draggable="false"
                    :src="c.icon"
                    :alt="c.name"
                    :title="c.name"
                    style="max-height: 40px"
                  />
                </td>
                <td class="vertical-middle">
                  <v-text-field
                    v-if="!c.noAmount"
                    type="number"
                    :rounded="rounded"
                    :color="color"
                    :dark="dark"
                    :background-color="bgColor"
                    :rules="[]"
                    :hide-details="details"
                    :outlined="outlined"
                    :filled="filled"
                    :dense="dense"
                    :readonly="allRead"
                    @keyup="calculateSubtotalBills"
                    @mouseup="calculateSubtotalBills"
                    v-model="c.amount"
                  >
                  </v-text-field>
                </td>
                <td
                  class="text-light text-right text-bill vertical-middle"
                  width="50px"
                >
                  <v-text-field
                    v-if="c.noAmount"
                    type="number"
                    :rounded="rounded"
                    :color="color"
                    :dark="dark"
                    :background-color="bgColor"
                    :rules="[]"
                    :hide-details="details"
                    :outlined="outlined"
                    :filled="filled"
                    :dense="dense"
                    :readonly="allRead"
                    @keyup="calculateSubtotalBills"
                    @mouseup="calculateSubtotalBills"
                    v-model="c.subtotal"
                  >
                  </v-text-field>
                  <Bill v-else :amount="c.subtotal"></Bill>
                </td>
              </tr>
            </template>
            <!-- SUB TOTAL Billetes -->
            <tr class="bg-dark">
              <th class="text-light text-right" colspan="2">
                Total Billetes
              </th>
              <th class="text-light text-right vertical-middle" width="50px">
                <Bill :amount="box.subtotalBills"></Bill>
              </th>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="col-6">
        <table class="table table-bordered table-bill w-100">
          <thead>
            <tr class="text-light bg-dark">
              <th colspan="3" class="text-center title-table-bill">
                MONEDAS
              </th>
            </tr>
            <tr class="text-light bg-dark">
              <th></th>
              <th>Cantidad</th>
              <th class="text-right" style="min-width: 120px">Monto</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(c, idx) in box.coins" :key="idx + c.name" class="">
              <td class="vertical-middle">
                <img
                  height="40"
                  draggable="false"
                  :src="c.icon"
                  :alt="c.name"
                  :title="c.name"
                  style="max-height: 40px"
                />
              </td>
              <td class="vertical-middle">
                <v-text-field
                  v-if="!c.noAmount"
                  type="number"
                  :rounded="rounded"
                  :color="color"
                  :dark="dark"
                  :background-color="bgColor"
                  :rules="[]"
                  :hide-details="details"
                  :outlined="outlined"
                  :filled="filled"
                  :dense="dense"
                  :readonly="allRead"
                  @keyup="calculateSubtotalCoins"
                  @change="calculateSubtotalCoins"
                  @mouseup="calculateSubtotalCoins"
                  v-model="c.amount"
                >
                </v-text-field>
              </td>
              <td class="text-light text-right text-bill vertical-middle">
                <v-text-field
                  v-if="c.noAmount"
                  type="number"
                  :rounded="rounded"
                  :color="color"
                  :dark="dark"
                  :background-color="bgColor"
                  :rules="[]"
                  :hide-details="details"
                  :outlined="outlined"
                  :filled="filled"
                  :dense="dense"
                  :readonly="allRead"
                  @keyup="calculateSubtotalCoins"
                  @change="calculateSubtotalCoins"
                  @mouseup="calculateSubtotalCoins"
                  v-model="c.subtotal"
                >
                </v-text-field>
                <Bill v-else :amount="c.subtotal"></Bill>
              </td>
            </tr>
            <!-- SUB TOTAL Coins -->
            <tr class="bg-dark">
              <th class="text-light text-right" colspan="2">
                Total Monedas
              </th>
              <th class="text-light text-right vertical-middle">
                <Bill :amount="box.subtotalCoins"></Bill>
              </th>
            </tr>
            <tr>
              <td></td>
            </tr>
            <tr class="bg-dark">
              <th class="text-light text-right" colspan="2">
                Total Efectivo
              </th>
              <th class="text-light text-right vertical-middle">
                <Bill :amount="box.totalBillsAndCoins"></Bill>
              </th>
            </tr>
            <tr v-for="(c, idx) in box.other" :key="idx + c.name" class="">
              <td class="vertical-middle">
                <img
                  height="40"
                  draggable="false"
                  :src="c.icon"
                  :alt="c.name"
                  :title="c.name"
                  style="max-height: 40px"
                />
              </td>
              <th class="text-light text-right">
                {{ c.name }}
              </th>
              <td
                class="text-light text-right text-bill vertical-middle"
                width="50px"
              >
                <v-text-field
                  v-if="c.noAmount"
                  type="number"
                  :rounded="rounded"
                  :color="color"
                  :dark="dark"
                  :background-color="bgColor"
                  :rules="[]"
                  :hide-details="details"
                  :outlined="outlined"
                  :filled="filled"
                  :dense="dense"
                  :readonly="allRead"
                  @keyup="calculateSubtotalCoins"
                  @change="calculateSubtotalCoins"
                  @mouseup="calculateSubtotalCoins"
                  v-model="c.subtotal"
                >
                </v-text-field>
                <Bill v-else :amount="c.subtotal"></Bill>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import Bill from "@/components/text/Bill";
export default {
  components: {
    Bill
  },
  props: {
    boxProp: Object,
    allRead: Boolean
  },
  data() {
    return {
      rounded: false,
      color: "#738297",
      bgColor: "#fff",
      required: [v => !!v || "Requerido"],
      upToZero: [v => (!!v && v > 0) || "Incorrecto"],
      disabled: false,
      details: true,
      filled: true,
      outlined: false,
      dense: true,
      autocomplete: "off",
      readonly: true,
      dark: false,
      // data
      box: {
        coins: [
          {
            name: "10 centimos",
            value: 0.1,
            icon: require("@/static/img/monedas/10.jpg"),
            amount: !this.allRead ? 0 : this.boxProp.CANTIDADM010,
            isVisible: true,
            noAmount: false,
            subtotal: !this.allRead ? 0 : this.boxProp.CANTIDADM010 * 0.1
          },
          {
            name: "20 centimos",
            value: 0.2,
            icon: require("@/static/img/monedas/20.jpg"),
            amount: !this.allRead ? 0 : this.boxProp.CANTIDADM020,
            isVisible: true,
            noAmount: false,
            subtotal: !this.allRead ? 0 : this.boxProp.CANTIDADM020 * 0.2
          },
          {
            name: "50 centimos",
            value: 0.5,
            icon: require("@/static/img/monedas/50.jpg"),
            amount: !this.allRead ? 0 : this.boxProp.CANTIDADM050,
            isVisible: true,
            noAmount: false,
            subtotal: !this.allRead ? 0 : this.boxProp.CANTIDADM050 * 0.5
          },
          {
            name: "1 sol",
            value: 1,
            icon: require("@/static/img/monedas/1.jpg"),
            amount: !this.allRead ? 0 : this.boxProp.CANTIDADM1,
            isVisible: true,
            noAmount: false,
            subtotal: !this.allRead ? 0 : this.boxProp.CANTIDADM1 * 1
          },
          {
            name: "2 soles",
            value: 2,
            icon: require("@/static/img/monedas/2.jpg"),
            amount: !this.allRead ? 0 : this.boxProp.CANTIDADM2,
            isVisible: true,
            noAmount: false,
            subtotal: !this.allRead ? 0 : this.boxProp.CANTIDADM2 * 2
          },
          {
            name: "5 soles",
            value: 5,
            icon: require("@/static/img/monedas/5.jpg"),
            amount: !this.allRead ? 0 : this.boxProp.CANTIDADM5,
            isVisible: true,
            noAmount: false,
            subtotal: !this.allRead ? 0 : this.boxProp.CANTIDADM5 * 5
          }
        ],
        bills: [
          {
            name: "10 soles",
            value: 10,
            icon: require("@/static/img/billetes/10.jpg"),
            amount: !this.allRead ? 0 : this.boxProp.CANTIDADB10,
            noAmount: false,
            isVisible: true,
            subtotal: !this.allRead ? 0 : this.boxProp.CANTIDADB10 * 10
          },
          {
            name: "20 soles",
            value: 20,
            icon: require("@/static/img/billetes/20.jpg"),
            amount: !this.allRead ? 0 : this.boxProp.CANTIDADB20,
            noAmount: false,
            isVisible: true,
            subtotal: !this.allRead ? 0 : this.boxProp.CANTIDADB20 * 20
          },
          {
            name: "50 soles",
            value: 50,
            icon: require("@/static/img/billetes/50.jpg"),
            amount: !this.allRead ? 0 : this.boxProp.CANTIDADB50,
            noAmount: false,
            isVisible: true,
            subtotal: !this.allRead ? 0 : this.boxProp.CANTIDADB50 * 50
          },
          {
            name: "100 soles",
            value: 100,
            icon: require("@/static/img/billetes/100.jpg"),
            amount: !this.allRead ? 0 : this.boxProp.CANTIDADB100,
            noAmount: false,
            isVisible: true,
            subtotal: !this.allRead ? 0 : this.boxProp.CANTIDADB50 * 100
          },
          {
            name: "200 soles",
            value: 200,
            icon: require("@/static/img/billetes/200.jpg"),
            amount: !this.allRead ? 0 : this.boxProp.CANTIDADB200,
            noAmount: false,
            isVisible: true,
            subtotal: !this.allRead ? 0 : this.boxProp.CANTIDADB200 * 200
          }
        ],
        other: [
          {
            name: "Tarjeta",
            value: 1,
            icon: require("@/static/img/tarjeta.png"),
            amount: !this.allRead ? 0 : this.boxProp.IMPORTETARJETA,
            noAmount: true,
            isVisible: true,
            subtotal: !this.allRead ? 0 : this.boxProp.IMPORTETARJETA
          },
          {
            name: "Billeteras Dígitales",
            value: 1,
            icon: require("@/static/img/qr.png"),
            amount: !this.allRead ? 0 : this.boxProp.IMPORTEQR,
            noAmount: true,
            isVisible: true,
            subtotal: !this.allRead ? 0 : this.boxProp.IMPORTEQR
          }
        ],
        subtotalBills: !this.allRead ? 0 : this.boxProp.IMPORTEBILLETES,
        subtotalCoins: !this.allRead ? 0 : this.boxProp.IMPORTEMONEDAS,
        totalBillsAndCoins: !this.allRead ? 0 : this.boxProp.IMPORTEEFECTIVO
      }
    };
  },
  created() {
    this.emitAll();
  },
  mounted() {
    this.calculateOthers();
  },
  methods: {
    calculateTotalBillsAndCoins() {
      this.box.totalBillsAndCoins =
        this.box.subtotalBills + this.box.subtotalCoins;
    },
    clearForm() {
      this.box.coins.forEach(c => {
        c.subtotal = 0;
        c.amount = 0;
      });
      this.box.bills.forEach(b => {
        b.subtotal = 0;
        b.amount = 0;
      });
      this.box.subtotalBills = 0;
      this.box.subtotalCoins = 0;
      this.box.totalBillsAndCoins = 0;
      this.calculateOthers();
    },
    calculateOthers() {
      this.calculateTotalBillsAndCoins();
      this.emitAll();
    },
    emitAll() {
      this.$emit("boxUpdated", { ...this.box });
    },
    calculateSubtotalBills() {
      let subtotal = 0;
      this.box.bills.forEach((bill, idx) => {
        // bills
        if (idx < 5) {
          // no entra la tarjeta de crédito
          if (bill.noAmount) {
            subtotal += bill.subtotal;
          } else {
            bill.subtotal = bill.amount
              ? (bill.value * parseInt(bill.amount) * 100) / 100
              : 0;
            bill.subtotal = parseFloat(bill.subtotal.toFixed(2));
            subtotal += bill.subtotal;
          }
        }
      });
      this.box.subtotalBills = subtotal;
      this.calculateOthers();
    },
    calculateSubtotalCoins() {
      let subtotal = 0;
      this.box.coins.forEach(bill => {
        // coins
        if (bill.noAmount) {
          subtotal += bill.subtotal;
        } else {
          bill.subtotal = bill.amount
            ? (bill.value * parseInt(bill.amount) * 100) / 100
            : 0;
          bill.subtotal = parseFloat(bill.subtotal.toFixed(2));
          subtotal += bill.subtotal;
        }
      });
      this.box.subtotalCoins = subtotal;
      this.calculateOthers();
    }
  }
};
</script>

<style lang="scss" scoped>
.table-bill {
  tbody {
    td {
      height: 52px;
    }
    td.h-auto {
      height: auto !important;
    }
  }
}
</style>
