<template>
  <div class="template-tickets">
    <v-container>
      <header class="projects-header">
        <TitleSection
          :title="'Cierre de Caja'"
          :subtitle="'Registra el cierre final de la boletería'"
        />
      </header>
      <FormBoxClose
        v-if="!loader"
        :key="baseIndex"
        :boxProp="boxProp"
        :boxOpen="boxOpen"
        @boxOpeningSaved="getBoxOpening"
      />
    </v-container>
  </div>
</template>

<script>
import TitleSection from "@/components/titles/TitleSection";
import FormBoxClose from "@/components/forms/FormBoxClose";
import axios from "@/api/entradaslima";
import store from "../../store";

export default {
  components: {
    TitleSection,
    FormBoxClose
  },
  data() {
    return {
      boxProp: null,
      boxOpen: null,
      loader: false,
      baseIndex: 154562,
      idParque: store.getters.getSessionParqueId,
      usuario: store.getters.getSessionUser.CORREO,
      idAsignacion: store.getters.getSessionIdAsignacion || 0
    };
  },
  async created() {
    this.loader = true;
    await this.getBoxOpening();
    this.loader = false;
  },
  methods: {
    async getBoxOpening() {
      const { data, status } = await axios.get(
        `/parques/${this.idParque}/asignaciones/${this.idAsignacion}/estados`
      );
      if (status != 200) return;
      this.boxProp =
        data.data
          .filter(item => item.ESTADO === 1)
          .find(item => item.TIPOESTADO === "CF") || {};
      this.boxOpen =
        data.data
          .filter(item => item.ESTADO === 1)
          .find(item => item.TIPOESTADO === "AI") || {};

      this.baseIndex += 5;
    }
  }
};
</script>

<style lang="scss" scoped></style>
